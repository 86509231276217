// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "x_tb d_gv d_cs";
export var heroHeaderCenter = "x_gw d_gw d_cs d_dw";
export var heroHeaderRight = "x_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "x_tc d_gr d_cw";
export var heroParagraphCenter = "x_gs d_gs d_cw d_dw";
export var heroParagraphRight = "x_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "x_td d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "x_tf d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "x_tg d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "x_th d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "x_tj d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "x_tk D_tk";
export var heroExceptionNormal = "x_tl D_tl";
export var heroExceptionLarge = "x_tm D_tm";
export var Title1Small = "x_tn D_tn D_t1 D_t2";
export var Title1Normal = "x_tp D_tp D_t1 D_t3";
export var Title1Large = "x_tq D_tq D_t1 D_t4";
export var BodySmall = "x_tr D_tr D_t1 D_vk";
export var BodyNormal = "x_ts D_ts D_t1 D_vl";
export var BodyLarge = "x_tt D_tt D_t1 D_vm";