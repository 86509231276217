// extracted by mini-css-extract-plugin
export var socialContainer = "z_tC";
export var instagramLink = "z_tD";
export var socialWrapperLeft = "z_nX d_dC d_Z d_bz d_bJ d_bp d_bG";
export var socialContentContainer = "z_tF";
export var socialContentContainerFull = "z_tG";
export var instagramContainer = "z_tH";
export var twitterContainer = "z_tJ";
export var facebookContainer = "z_tK";
export var socialErrorContainer = "z_tL";
export var facebookContainerWide = "z_tM";
export var twitterContainerWide = "z_tN";
export var socialParagraphCenter = "z_tP";
export var instaWrapper = "z_tQ";
export var SubtitleSmall = "z_qd D_qd D_t1 D_vc";
export var SubtitleNormal = "z_qf D_qf D_t1 D_vd";
export var SubtitleLarge = "z_qg D_qg D_t1 D_vf";
export var textLeft = "z_dv";
export var textCenter = "z_dw";
export var textRight = "z_dx";