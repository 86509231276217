// extracted by mini-css-extract-plugin
export var lbContainerOuter = "G_wc";
export var lbContainerInner = "G_wd";
export var movingForwards = "G_wf";
export var movingForwardsOther = "G_wg";
export var movingBackwards = "G_wh";
export var movingBackwardsOther = "G_wj";
export var lbImage = "G_wk";
export var lbOtherImage = "G_wl";
export var prevButton = "G_wm";
export var nextButton = "G_wn";
export var closing = "G_wp";
export var appear = "G_wq";