// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "s_rS d_bD";
export var datasheetRowWrapper = "s_lt d_lt d_bK";
export var datasheetLeftWrapper = "s_rT d_bz d_bP";
export var datasheetWrapperFull = "s_rV d_cD";
export var datasheetWrapperFullLeft = "s_pf d_cD d_bz d_bP d_bD d_bT";
export var contentWrapper = "s_mv d_lv";
export var contentWrapperCenter = "s_rW d_lz";
export var datasheetLeftWrapperCenter = "s_rX d_lx d_bz d_bD d_bP d_bJ";
export var datasheetImageCenterWrapper = "s_lr d_lr d_w d_cv";
export var datasheetRightWrapperCenter = "s_rY d_lw";
export var datasheetFooter = "s_ly d_ly";
export var alignLeft = "s_qh d_dv";
export var alignCenter = "s_bP d_dw";
export var alignRight = "s_qj d_dx";
export var datasheetInnerWrapperNewLeft = "s_rZ d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetInnerWrapperLeft = "s_r0 d_fd d_P d_Z d_bz d_bP d_b5 d_dj d_c9";
export var datasheetInnerWrapperRight = "s_r1 d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetRowWrapperReverse = "s_r2 d_bK";
export var datasheetSubtitle = "s_r3 d_cx";
export var tableRow = "s_r4";
export var cell = "s_r5";
export var tableRowWrapper = "s_r6 d_w d_dv";
export var tableHeadingCell = "s_r7";
export var tableHeading = "s_r8";
export var tableRowStriped = "s_r9";
export var tableRowHeading = "s_sb";
export var dataTable = "s_sc d_w";
export var imageWrapper = "s_sd d_fg d_Z";
export var imageWrapperFull = "s_sf d_H d_w d_bf d_Z";
export var imageWrapperIcon = "s_sg";
export var titleMargin = "s_sh d_cs";
export var datasheetInnerInnerWrapper = "s_sj d_w";
export var hasLabels = "s_sk";
export var label = "s_sl";
export var SmallSmall = "s_sm D_sm D_t1 D_vn";
export var SmallNormal = "s_sn D_sn D_t1 D_vp";
export var SmallLarge = "s_sp D_sp D_t1 D_vl";